import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  player1: {
    id: '',
    health: 0,
    itemsList: [], // List of { index: number, item: string }
    cuffed: false,
  },
  player2: {
    id: '',
    health: 0,
    itemsList: [],
    cuffed: false,
  },
  playeridturn: '', // ID of the player whose turn it is
  initialmessage: false, // Indicates whether this is the initial message
  gameinfo: {
    reloadperformed: false,
    gamestatus: 0, // GameLifeStatus enum: 'Going', 'PlayerWon', 'PlayerDc'
    winnerid: '',
    lastshotlive: false,
    totalbullets: 0,
    livebullets: 0,
  },
  playeridlastshooter: '', // ID of the last player who shot
  playeridlasttarget: '', // ID of the last player who was targeted
  itemaction: {
    useditem: 0, // Item enum: 'None', 'Heal', etc.
    itemuser: '',
    nextshotdoubledamage: false,
    lookedbulletwaslive: false,
  },
  timestamp: 0, // Unix timestamp
  version: '', // Version of the game state protocol
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGameState: (state, action) => {
      // Replace the entire game state with new data
      return { ...state, ...action.payload };
    },
    resetGameState: () => initialState, // Reset the game state to initial
  },
});


export const { updateGameState, resetGameState } = gameSlice.actions;
export default gameSlice.reducer;