import Phaser from 'phaser';
import GameplayScene from './Phaser/GameplayScene.js'

const config = {
  // Use the WebGL renderer if available, otherwise fallback to Canvas
  // If you know you want only WebGL or only Canvas, you can force it:
  // type: Phaser.WEBGL,
  type: Phaser.AUTO,

  // If you still need a fixed game size:
  width: 1080,
  height: 1920,
  parent: 'phaser-container',

  // If you don’t need physics at all, remove (or comment out) the physics section
  // physics: {
  //   default: 'arcade',
  //   arcade: {
  //     debug: false,
  //   },
  // },

  scene: [GameplayScene],

  scale: {
    // EXPAND mode tries to fill the parent while maintaining aspect ratio
    mode: Phaser.Scale.EXPAND,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },

  // If you don’t need audio, you can disable or skip loading audio entirely:
  // audio: {
  //   noAudio: true
  // },

  // Limit the FPS to reduce CPU/GPU load on mobile
  fps: {
    target: 30,            // Target frames per second (30 is often acceptable for mobile)
    forceSetTimeOut: true, // Ensures Phaser uses setTimeout for the timing step
  },

  // Optional renderer config to improve performance
  render: {
    // Turn off anti-aliasing if you don’t need it (especially if you have pixel art)
    antialias: false,
    // Rounds pixel positions to whole integers. Can improve performance for sprite-based games.
    roundPixels: true,
  },
};

export const initializePhaser = () => {
  const game = new Phaser.Game(config);
  return game;
};