export class ScreenSizeHelper {
    scene;
    top_aligned_elements;
    bot_aligned_elements;
    middle_aligned_elements;
    middle_bg_image;
    bottom_bg_image;
    top_bg_image;

    refHeight;
    topPositions;
    bottomPositions;
    originalMiddleHeight;


    constructor(scene, top_aligned_elements, bot_aligned_elements, middle_aligned_elements, middle_bg_image, bottom_bg_image, top_bg_image) {
        this.scene = scene;
        this.top_aligned_elements = top_aligned_elements;
        this.bot_aligned_elements = bot_aligned_elements;
        this.middle_bg_image = middle_bg_image;
        this.bottom_bg_image = bottom_bg_image;
        this.middle_aligned_elements = middle_aligned_elements;
        this.top_bg_image = top_bg_image;
    }

	prepareForScaling() {
    	// Set the reference height based on your known initial design:
		this.refHeight = 1920;

		// Store original positions of top and bottom elements
		this.topPositions = this.top_aligned_elements.map(obj => obj.y);
		this.bottomPositions = this.bot_aligned_elements.map(obj => obj.y);

		// Store the original middle height
		this.originalMiddleHeight = this.middle_bg_image.displayHeight;

		this.storeBottomElementsRatios();
		this.storeMiddleElementsRatios();

		this.scene.scale.on('resize', this.onResize, this);
        this.onResize(this.scene.scale.gameSize);

	}


	storeBottomElementsRatios() {
		const bg = this.bottom_bg_image;
		const bgAnchorX = bg.x - (bg.displayWidth * bg.originX);
		const bgAnchorY = bg.y - (bg.displayHeight * bg.originY);

		for (let elem of this.bot_aligned_elements) {
			elem._relativeX = (elem.x - bgAnchorX) / bg.displayWidth;
			elem._relativeY = (elem.y - bgAnchorY) / bg.displayHeight;
			elem._relativeScaleX = elem.scaleX / bg.scaleX;
			elem._relativeScaleY = elem.scaleY / bg.scaleY;
		}
	}

	storeMiddleElementsRatios() {
		const mp = this.middle_bg_image;

		// Midpoint of the middle_part:
		const mpCenterX = mp.x; 
		const mpCenterY = mp.y + (mp.displayHeight * 0.5);

		for (let elem of this.middle_aligned_elements) {
			// Store relative position as a fraction of middle_part's dimensions
			// so that the element's position scales with the size of middle_part.
			elem._relativeX = (elem.x - mpCenterX) / mp.displayWidth;
			elem._relativeY = (elem.y - mpCenterY) / mp.displayHeight;
		}
	}

	onResize(gameSize) {
		let width = gameSize.width;
		let height = gameSize.height;
		// Reposition top-aligned elements:
		// Same relative distance from top = (originalY / refHeight) * currentHeight
		for (let i = 0; i < this.top_aligned_elements.length; i++) {
			const obj = this.top_aligned_elements[i];
			const originalY = this.topPositions[i]; // stored in prepareForScaling
			if (obj.setY) {
				obj.setY((originalY / this.refHeight) * height);
			}
		}

		// Reposition bottom-aligned elements:
		// Same relative distance from bottom:
		// Distance from bottom at ref = refHeight - originalY
		// newY = height - ((refHeight - originalY) / refHeight) * height
		for (let i = 0; i < this.bot_aligned_elements.length; i++) {
			const obj = this.bot_aligned_elements[i];
			const originalY = this.bottomPositions[i]; // stored in prepareForScaling
			if (obj.setY) {
				const refDistFromBottom = this.refHeight - originalY;
				obj.setY(height - (refDistFromBottom / this.refHeight) * height);
			}
		}

		// Now calculate the space for the middle:
		let topHeight = this.top_bg_image.displayHeight; 
		let bottomHeight = this.bottom_bg_image.displayHeight;
		let availableMiddleHeight = height - topHeight - bottomHeight;

		let currentHeight = this.middle_bg_image.displayHeight;
		let currentScale = this.middle_bg_image.scaleY;

		let desiredScale = availableMiddleHeight * currentScale / currentHeight;
		// Position the middle_objects right below the top_bg:
		this.middle_bg_image.setY(topHeight);
		this.middle_bg_image.setScale(this.middle_bg_image.scaleX, desiredScale);


		// After you've updated bottom_bg position and scale:
		const bg = this.bottom_bg_image;
		const bgAnchorX = bg.x - (bg.displayWidth * bg.originX);
		const bgAnchorY = bg.y - (bg.displayHeight * bg.originY);

		for (let elem of this.bot_aligned_elements) {
			// Restore position based on new bg dimensions
			elem.setX(bgAnchorX + elem._relativeX * bg.displayWidth);
			elem.setY(bgAnchorY + elem._relativeY * bg.displayHeight);

			// Restore scale to match the new bottom_bg scale
			elem.setScale(bg.scaleX * elem._relativeScaleX, bg.scaleY * elem._relativeScaleY);
		}

		// Inside onResize(), after adjusting middle_part:
		// Inside onResize(), after adjusting middle_part's size and position:
		const mp = this.middle_bg_image;
		const mpCenterX = mp.x; 
		const mpCenterY = mp.y + (mp.displayHeight * 0.5);

		for (let elem of this.middle_aligned_elements) {
			// Reposition based on the new size of middle_part
			elem.setX(mpCenterX + (elem._relativeX * mp.displayWidth));
			elem.setY(mpCenterY + (elem._relativeY * mp.displayHeight));
			// No scaling applied
		}
	}
}