import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  id: 0
}

const localPlayerSlice = createSlice({
  name: "localPlayer",
  initialState,
  reducers: {
    setLocalPlayer: (state, action) => {
      return { ...state, ...action.payload};
    },
    resetState: () => initialState, // Reset the game state to initial
  }
})

export const { setLocalPlayer } = localPlayerSlice.actions;
export default localPlayerSlice.reducer;