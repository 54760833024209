
// You can write more code here
import { Animations } from "./AnimationEffects.js";
import { ScreenSizeHelper } from "./ScreenSizeHelper.js";
import store from '../../store';
import Phaser from 'phaser';

/* START OF COMPILED CODE */

class GameplayScene extends Phaser.Scene {

	constructor() {
		super("GameplayScene");

		/* START-USER-CTR-CODE */
		// Write your code here.
		/* END-USER-CTR-CODE */
	}

	/** @returns {void} */
	preload() {

		this.load.pack("asset-pack", "assets/images/asset-pack.json");
	}

	/** @returns {void} */
	editorCreate() {

		// layer_bot
		const layer_bot = this.add.layer();

		// bottom_bg
		const bottom_bg = this.add.image(550, 1920, "white_bg");
		bottom_bg.scaleX = -588.4751215410375;
		bottom_bg.scaleY = 45.952182129421885;
		bottom_bg.setOrigin(0.5, 1);
		bottom_bg.tintTopLeft = 13551997;
		bottom_bg.tintTopRight = 13551997;
		bottom_bg.tintBottomLeft = 13551997;
		bottom_bg.tintBottomRight = 13551997;
		layer_bot.add(bottom_bg);

		// revolver_gun
		const revolver_gun = this.add.image(314, 1826, "revolver_sheet", 2);
		revolver_gun.scaleX = 1.6553880328323825;
		revolver_gun.scaleY = 1.6553880328323825;
		revolver_gun.angle = -24;
		revolver_gun.setOrigin(0.5, 1);
		revolver_gun.flipX = true;
		layer_bot.add(revolver_gun);

		// shadowFx
		revolver_gun.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// money_pile
		const money_pile = this.add.image(374, 1816, "money_pile");
		money_pile.setOrigin(0.5, 1);
		layer_bot.add(money_pile);

		// glowFx
		money_pile.preFX.addGlow(16777215, 4, 0, false);

		// my_item_4
		const my_item_4 = this.add.image(945, 1401, "white_bg");
		my_item_4.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		my_item_4.scaleX = 0.7;
		my_item_4.scaleY = 0.7;
		my_item_4.setOrigin(0.5, 1);
		layer_bot.add(my_item_4);

		// shadowFx_4
		my_item_4.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// my_item_3
		const my_item_3 = this.add.image(749, 1401, "white_bg");
		my_item_3.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		my_item_3.scaleX = 0.7;
		my_item_3.scaleY = 0.7;
		my_item_3.setOrigin(0.5, 1);
		layer_bot.add(my_item_3);

		// shadowFx_5
		my_item_3.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// my_item_2
		const my_item_2 = this.add.image(556, 1401, "white_bg");
		my_item_2.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		my_item_2.scaleX = 0.7;
		my_item_2.scaleY = 0.7;
		my_item_2.setOrigin(0.5, 1);
		layer_bot.add(my_item_2);

		// shadowFx_6
		my_item_2.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// my_item_1
		const my_item_1 = this.add.image(359, 1401, "white_bg");
		my_item_1.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		my_item_1.scaleX = 0.7;
		my_item_1.scaleY = 0.7;
		my_item_1.setOrigin(0.5, 1);
		layer_bot.add(my_item_1);

		// shadowFx_3
		my_item_1.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// bt_shootSelf_ctn
		const bt_shootSelf_ctn = this.add.container(869, 1627);
		layer_bot.add(bt_shootSelf_ctn);

		// btShootSelfImage
		const btShootSelfImage = this.add.image(0, 0, "white_bg");
		btShootSelfImage.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		btShootSelfImage.scaleX = 22;
		btShootSelfImage.scaleY = 8;
		btShootSelfImage.setOrigin(0.5, 1);
		bt_shootSelf_ctn.add(btShootSelfImage);

		// text_1
		const text_1 = this.add.text(-102, -5, "", {});
		text_1.setOrigin(0, 1);
		text_1.text = "SELF";
		text_1.setStyle({ "color": "#050505ff", "fixedWidth": 256, "fixedHeight": 100, "fontSize": "80px" });
		bt_shootSelf_ctn.add(text_1);

		// bt_show_other_ctn
		const bt_show_other_ctn = this.add.container(870, 1818);
		layer_bot.add(bt_show_other_ctn);

		// btShootOtherImage
		const btShootOtherImage = this.add.image(0, 0, "white_bg");
		btShootOtherImage.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		btShootOtherImage.scaleX = 22;
		btShootOtherImage.scaleY = 8;
		btShootOtherImage.setOrigin(0.5, 1);
		bt_show_other_ctn.add(btShootOtherImage);

		// text
		const text = this.add.text(-127, -13, "", {});
		text.setOrigin(0, 1);
		text.text = "OTHER";
		text.setStyle({ "color": "#050505ff", "fixedWidth": 256, "fixedHeight": 100, "fontSize": "80px" });
		bt_show_other_ctn.add(text);

		// layer_mid
		const layer_mid = this.add.layer();

		// middle_part
		const middle_part = this.add.image(552, 353, "white_bg");
		middle_part.scaleX = 259.30070507189174;
		middle_part.scaleY = 52.04358438732783;
		middle_part.setOrigin(0.5, 0);
		middle_part.tintTopLeft = 2050665;
		middle_part.tintTopRight = 13903655;
		middle_part.tintBottomLeft = 2050665;
		middle_part.tintBottomRight = 13903655;
		layer_mid.add(middle_part);

		// character
		const character = this.add.image(917, 740, "character_placeholder");
		character.scaleX = 3.6;
		character.scaleY = 3.6;
		layer_mid.add(character);

		// shadowFx_1
		character.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// character_1
		const character_1 = this.add.image(173, 743, "character_placeholder");
		character_1.scaleX = 3.6;
		character_1.scaleY = 3.6;
		layer_mid.add(character_1);

		// shadowFx_2
		character_1.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// hearts_right
		const hearts_right = this.add.container(666, 750);
		layer_mid.add(hearts_right);

		// h3
		const h3 = this.add.image(0, -145, "hearts");
		hearts_right.add(h3);

		// glowFx_2
		h3.preFX.addGlow(16777215, 4, 0, false);

		// h2
		const h2 = this.add.image(0, 0, "hearts");
		hearts_right.add(h2);

		// glowFx_1
		h2.preFX.addGlow(16777215, 4, 0, false);

		// h1
		const h1 = this.add.image(0, 133, "hearts");
		hearts_right.add(h1);

		// glowFx_3
		h1.preFX.addGlow(16777215, 4, 0, false);

		// hearts_left
		const hearts_left = this.add.container(429, 750);
		layer_mid.add(hearts_left);

		// l_h3
		const l_h3 = this.add.image(0, -145, "hearts");
		hearts_left.add(l_h3);

		// glowFx_7
		l_h3.preFX.addGlow(16777215, 4, 0, false);

		// l_h2
		const l_h2 = this.add.image(0, 0, "hearts");
		hearts_left.add(l_h2);

		// glowFx_8
		l_h2.preFX.addGlow(16777215, 4, 0, false);

		// l_h1
		const l_h1 = this.add.image(0, 133, "hearts");
		hearts_left.add(l_h1);

		// glowFx_9
		l_h1.preFX.addGlow(16777215, 4, 0, false);

		// layer_top
		const layer_top = this.add.layer();

		// top_bg
		const top_bg = this.add.image(550, 0, "white_bg");
		top_bg.scaleX = 213.15281865122384;
		top_bg.scaleY = 22.442529535964386;
		top_bg.setOrigin(0.5, 0);
		top_bg.tintTopLeft = 9941982;
		top_bg.tintTopRight = 9941982;
		top_bg.tintBottomLeft = 9941982;
		top_bg.tintBottomRight = 9941982;
		layer_top.add(top_bg);

		// other_item_4
		const other_item_4 = this.add.image(716, 162, "white_bg");
		other_item_4.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		other_item_4.scaleX = 0.7;
		other_item_4.scaleY = 0.7;
		other_item_4.setOrigin(0.5, 0);
		layer_top.add(other_item_4);

		// shadowFx_8
		other_item_4.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// other_item_3
		const other_item_3 = this.add.image(516, 162, "white_bg");
		other_item_3.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		other_item_3.scaleX = 0.7;
		other_item_3.scaleY = 0.7;
		other_item_3.setOrigin(0.5, 0);
		layer_top.add(other_item_3);

		// shadowFx_9
		other_item_3.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// other_item_2
		const other_item_2 = this.add.image(318, 162, "white_bg");
		other_item_2.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		other_item_2.scaleX = 0.7;
		other_item_2.scaleY = 0.7;
		other_item_2.setOrigin(0.5, 0);
		layer_top.add(other_item_2);

		// shadowFx_10
		other_item_2.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// other_item_1
		const other_item_1 = this.add.image(118, 162, "cigi");
		other_item_1.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		other_item_1.scaleX = 0.7;
		other_item_1.scaleY = 0.7;
		other_item_1.setOrigin(0.5, 0);
		layer_top.add(other_item_1);

		// shadowFx_7
		other_item_1.preFX.addShadow(0, 0, 0.1, 1, 0, 6, 1);

		// opponent_name
		const opponent_name = this.add.text(28, 56, "", {});
		opponent_name.tintTopLeft = 0;
		opponent_name.tintTopRight = 0;
		opponent_name.tintBottomLeft = 0;
		opponent_name.tintBottomRight = 0;
		opponent_name.text = "OpponentName";
		opponent_name.setStyle({ "fontSize": "70px" });
		layer_top.add(opponent_name);

		// layer_popup
		const layer_popup = this.add.layer();

		// popup_container
		const popup_container = this.add.container(1748, 965);
		layer_popup.add(popup_container);

		// popup_bg
		const popup_bg = this.add.image(0, 0, "white_bg");
		popup_bg.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		popup_bg.scaleX = 66.55138964599804;
		popup_bg.scaleY = 123.80603211370409;
		popup_bg.tintTopLeft = 13619151;
		popup_bg.tintTopRight = 13619151;
		popup_bg.tintBottomLeft = 13619151;
		popup_bg.tintBottomRight = 13619151;
		popup_container.add(popup_bg);

		// popup_anchor_centre
		const popup_anchor_centre = this.add.container(-11.99993896484375, -518);
		popup_container.add(popup_anchor_centre);

		// item_popup_desc
		const item_popup_desc = this.add.text(35.49993896484375, 593, "", {});
		item_popup_desc.setOrigin(0.5, 0);
		item_popup_desc.text = "Example text";
		item_popup_desc.setStyle({ "align": "center", "fixedWidth": 1080, "fixedHeight": 300, "fontSize": "80px" });
		item_popup_desc.setWordWrapWidth(900, true);
		popup_anchor_centre.add(item_popup_desc);

		// item_popup_image
		const item_popup_image = this.add.image(1, 53, "cigi");
		item_popup_image.scaleX = 1.8757653008237116;
		item_popup_image.scaleY = 1.8757653008237116;
		item_popup_image.setOrigin(0.5, 0);
		popup_anchor_centre.add(item_popup_image);

		// item_use_ctn
		const item_use_ctn = this.add.container(3, 1273);
		popup_anchor_centre.add(item_use_ctn);

		// bt_item_use
		const bt_item_use = this.add.image(0, 0, "white_bg");
		bt_item_use.setInteractive(new Phaser.Geom.Rectangle(0, 0, 256, 256), Phaser.Geom.Rectangle.Contains);
		bt_item_use.scaleX = 22;
		bt_item_use.scaleY = 8;
		item_use_ctn.add(bt_item_use);

		// item_use_text
		const item_use_text = this.add.text(-129, -45, "", {});
		item_use_text.text = "Use";
		item_use_text.setStyle({ "align": "center", "color": "#000000ff", "fixedWidth": 256, "fontSize": "80px" });
		item_use_ctn.add(item_use_text);

		// reload_container
		const reload_container = this.add.container(-2697, 960);
		layer_popup.add(reload_container);

		// reload_bg
		const reload_bg = this.add.image(0, 0, "white_bg");
		reload_bg.scaleX = 66.55138964599804;
		reload_bg.scaleY = 123.80603211370409;
		reload_bg.tintTopLeft = 16632008;
		reload_bg.tintTopRight = 16632008;
		reload_bg.tintBottomLeft = 16632008;
		reload_bg.tintBottomRight = 16632008;
		reload_container.add(reload_bg);

		// reload_elements
		const reload_elements = this.add.container(17, -567);
		reload_container.add(reload_elements);

		// bullet
		const bullet = this.add.image(9, 1227, "bullet_sheet", 1);
		bullet.scaleX = 1.7728622515894272;
		bullet.scaleY = 1.7728622515894272;
		reload_elements.add(bullet);

		// cylinder
		const cylinder = this.add.image(0, 357, "cylinder_sheet", 6);
		cylinder.scaleX = 3.8327119412009276;
		cylinder.scaleY = 3.8327119412009276;
		reload_elements.add(cylinder);

		// shooting_container
		const shooting_container = this.add.container(-723, 957);
		shooting_container.scaleX = 1.0745345884836734;
		shooting_container.scaleY = 0.9778373691872747;
		layer_popup.add(shooting_container);

		// shootPopupBg
		const shootPopupBg = this.add.image(0, 0, "white_bg");
		shootPopupBg.scaleX = 66.55138964599804;
		shootPopupBg.scaleY = 123.80603211370409;
		shootPopupBg.tintTopLeft = 15791832;
		shootPopupBg.tintTopRight = 15791832;
		shootPopupBg.tintBottomLeft = 15791832;
		shootPopupBg.tintBottomRight = 15791832;
		shooting_container.add(shootPopupBg);

		// shooting_elements
		const shooting_elements = this.add.container(-233, -527);
		shooting_container.add(shooting_elements);

		// character_placeholder
		const character_placeholder = this.add.image(0, 576, "character_placeholder");
		character_placeholder.scaleX = 7.362359691333934;
		character_placeholder.scaleY = 7.362359691333934;
		shooting_elements.add(character_placeholder);

		// shot_effect
		const shot_effect = this.add.image(20, 38, "shot_effect");
		shot_effect.scaleX = 2.5;
		shot_effect.scaleY = 2.5;
		shooting_elements.add(shot_effect);

		// shooting_gun
		const shooting_gun = this.add.container(534, 1159);
		shooting_elements.add(shooting_gun);

		// gun_s
		const gun_s = this.add.image(-73, 5, "revolver_sheet", 2);
		gun_s.scaleX = 2.5;
		gun_s.scaleY = 2.5;
		shooting_gun.add(gun_s);

		// muzzle
		const muzzle = this.add.image(-425, -120, "muzzle", 0);
		muzzle.scaleX = 1.2754808992022721;
		muzzle.scaleY = 1.2754808992022721;
		muzzle.angle = 11;
		shooting_gun.add(muzzle);

		// lists
		const align_top = [top_bg];
		const fill_middle = [layer_mid];
		const bottom_elements = [text, btShootOtherImage, my_item_2, my_item_3, my_item_4, my_item_1, money_pile, revolver_gun, bottom_bg, item_use_ctn, bt_shootSelf_ctn, bt_show_other_ctn];
		const middle_elements = [character, character_1, hearts_right, hearts_left];
		const my_items = [my_item_1, my_item_2, my_item_3, my_item_4];
		const other_items = [other_item_1, other_item_2, other_item_3, other_item_4];
		const my_hearts = [h1, h2, h3];
		const other_hearts = [l_h1, l_h2, l_h3];

		this.bottom_bg = bottom_bg;
		this.my_item_1 = my_item_1;
		this.btShootSelfImage = btShootSelfImage;
		this.bt_shootSelf_ctn = bt_shootSelf_ctn;
		this.btShootOtherImage = btShootOtherImage;
		this.bt_show_other_ctn = bt_show_other_ctn;
		this.middle_part = middle_part;
		this.hearts_right = hearts_right;
		this.hearts_left = hearts_left;
		this.top_bg = top_bg;
		this.opponent_name = opponent_name;
		this.popup_bg = popup_bg;
		this.item_popup_desc = item_popup_desc;
		this.item_popup_image = item_popup_image;
		this.bt_item_use = bt_item_use;
		this.item_use_ctn = item_use_ctn;
		this.popup_anchor_centre = popup_anchor_centre;
		this.popup_container = popup_container;
		this.reload_bg = reload_bg;
		this.bullet = bullet;
		this.cylinder = cylinder;
		this.reload_elements = reload_elements;
		this.reload_container = reload_container;
		this.shootPopupBg = shootPopupBg;
		this.shot_effect = shot_effect;
		this.gun_s = gun_s;
		this.muzzle = muzzle;
		this.shooting_gun = shooting_gun;
		this.shooting_elements = shooting_elements;
		this.shooting_container = shooting_container;
		this.align_top = align_top;
		this.fill_middle = fill_middle;
		this.bottom_elements = bottom_elements;
		this.middle_elements = middle_elements;
		this.my_items = my_items;
		this.other_items = other_items;
		this.my_hearts = my_hearts;
		this.other_hearts = other_hearts;

		this.events.emit("scene-awake");
	}

	/** @type {Phaser.GameObjects.Image} */
	bottom_bg;
	/** @type {Phaser.GameObjects.Image} */
	my_item_1;
	/** @type {Phaser.GameObjects.Image} */
	btShootSelfImage;
	/** @type {Phaser.GameObjects.Container} */
	bt_shootSelf_ctn;
	/** @type {Phaser.GameObjects.Image} */
	btShootOtherImage;
	/** @type {Phaser.GameObjects.Container} */
	bt_show_other_ctn;
	/** @type {Phaser.GameObjects.Image} */
	middle_part;
	/** @type {Phaser.GameObjects.Container} */
	hearts_right;
	/** @type {Phaser.GameObjects.Container} */
	hearts_left;
	/** @type {Phaser.GameObjects.Image} */
	top_bg;
	/** @type {Phaser.GameObjects.Text} */
	opponent_name;
	/** @type {Phaser.GameObjects.Image} */
	popup_bg;
	/** @type {Phaser.GameObjects.Text} */
	item_popup_desc;
	/** @type {Phaser.GameObjects.Image} */
	item_popup_image;
	/** @type {Phaser.GameObjects.Image} */
	bt_item_use;
	/** @type {Phaser.GameObjects.Container} */
	item_use_ctn;
	/** @type {Phaser.GameObjects.Container} */
	popup_anchor_centre;
	/** @type {Phaser.GameObjects.Container} */
	popup_container;
	/** @type {Phaser.GameObjects.Image} */
	reload_bg;
	/** @type {Phaser.GameObjects.Image} */
	bullet;
	/** @type {Phaser.GameObjects.Image} */
	cylinder;
	/** @type {Phaser.GameObjects.Container} */
	reload_elements;
	/** @type {Phaser.GameObjects.Container} */
	reload_container;
	/** @type {Phaser.GameObjects.Image} */
	shootPopupBg;
	/** @type {Phaser.GameObjects.Image} */
	shot_effect;
	/** @type {Phaser.GameObjects.Image} */
	gun_s;
	/** @type {Phaser.GameObjects.Image} */
	muzzle;
	/** @type {Phaser.GameObjects.Container} */
	shooting_gun;
	/** @type {Phaser.GameObjects.Container} */
	shooting_elements;
	/** @type {Phaser.GameObjects.Container} */
	shooting_container;
	/** @type {Phaser.GameObjects.Image[]} */
	align_top;
	/** @type {Phaser.GameObjects.Layer[]} */
	fill_middle;
	/** @type {Array<Phaser.GameObjects.Text|Phaser.GameObjects.Image|Phaser.GameObjects.Container>} */
	bottom_elements;
	/** @type {Array<Phaser.GameObjects.Image|Phaser.GameObjects.Container>} */
	middle_elements;
	/** @type {Phaser.GameObjects.Image[]} */
	my_items;
	/** @type {Phaser.GameObjects.Image[]} */
	other_items;
	/** @type {Phaser.GameObjects.Image[]} */
	my_hearts;
	/** @type {Phaser.GameObjects.Image[]} */
	other_hearts;

	/* START-USER-CODE */
	animations;
	screenSizeHelper;
	itemImagesMap;
	itemDescriptionsMap;
	localplayerId;


	create() {
		this.unsubscribe = store.subscribe(() => {
			const state = store.getState();
			this.updateGame(state.game);
		});

		this.itemImagesMap = new Map([
			[0, "white_bg"],
			[1, "cigi"],
			[2, "hand_saw"],
			[3, "handcuffs"],
			[4, "looking_glass"],
			[5, "beer"]
		]);

		this.itemDescriptionsMap = new Map([
			[0, ""],
			[1, "Heal 1 missing hp"],
			[2, "Double damage on the next shot"],
			[3, "Opponent skips their next turn"],
			[4, "See if the catrige is live"],
			[5, "Discard catrige without skipping the turn"]
		]);

		this.animations = new Animations(this);
		this.editorCreate();

		this.localplayerId = store.getState().localPlayer.id;



		this.screenSizeHelper = new ScreenSizeHelper(this, this.align_top, this.bottom_elements, this.middle_elements, this.middle_part, this.bottom_bg, this.top_bg);

		this.screenSizeHelper.prepareForScaling();
		this.setupButtons();

		// for (let elem of this.my_items) {
		// 	const randomNum = Math.floor(Math.random() * 6);  // Random number between 0 and 5
		// 	const image = this.itemImagesMap.get(randomNum);
		// 	elem.setTexture(image);
		// 	if (randomNum != 0) {
		// 		elem.on('pointerdown', () => {
		// 			this.item_popup_image.setTexture(image);
		// 			this.item_use_ctn.setVisible(true);
		// 			this.item_popup_desc.text = this.itemDescriptionsMap.get(randomNum);
		// 			this.animations.showPopup(this.popup_container, this.popup_bg, this.popup_anchor_centre);
		// 		});
		// 	}
		// }

		// for (let elem of this.other_items) {
		// 	const randomNum = Math.floor(Math.random() * 6);  // Random number between 0 and 5
		// 	const image = this.itemImagesMap.get(randomNum);
		// 	console.log('Setting ' + image + ' for ' + randomNum)
		// 	elem.setTexture(image);
		// 	if (randomNum != 0) {
		// 		elem.on('pointerdown', () => {
		// 			this.item_use_ctn.setVisible(false);
		// 			this.item_popup_image.setTexture(image);
		// 			this.item_popup_desc.text = this.itemDescriptionsMap.get(randomNum);
		// 			this.animations.showPopup(this.popup_container, this.popup_bg, this.popup_anchor_centre);
		// 		});
		// 	}
		// }

		const state = store.getState();
		this.updateGame(state.game);
	}

	setupButtons() {


		this.btShootSelfImage.on('pointerdown', () => {
			this.animations.showPopup(this.shooting_container, this.shootPopupBg, this.shooting_elements);
			this.animations.setupShootingAnimations(this.shooting_gun, this.gun_s, this.shot_effect, this.muzzle, () => {
				this.animations.hidePopup(this.shootPopupBg, this.shooting_container);
			});
		});

		this.btShootOtherImage.on('pointerdown', () => {
			this.animations.showPopup(this.reload_container, this.reload_bg, this.reload_elements);
			this.animations.setupReloadAnimations(5, this.bullet, this.cylinder, () => {
				this.animations.hidePopup(this.reload_bg, this.reload_container);
			});
		});

		//setup item click handler
		let index = 0;
		for (let itemImage of this.my_items) {
			const currentIndex = index;
			itemImage.on("pointerdown", () => {
				this.handleItemClick(currentIndex, true);
			});
			index++;
		}
		index = 0;
		for (let itemImage of this.other_items) {
			const currentIndex = index;
			itemImage.on("pointerdown", () => {
				this.handleItemClick(currentIndex, false);
			});
			index++;
		}



		this.popup_bg.on('pointerdown', () => {
			this.animations.hidePopup(this.popup_bg, this.popup_container);
		});
	}

	updateGame(gameState) {
		//TODO: Read message, play animations accordingly
		let localPlayer = gameState.player1;
		let otherPlayer = gameState.player2;
		if (localPlayer.id !== this.localplayerId) {
			const temp = localPlayer;
			localPlayer = otherPlayer;
			otherPlayer = temp;
		}

		const isPlayerTurn = gameState.playeridturn === localPlayer.id;
		this.bt_shootSelf_ctn.setVisible(isPlayerTurn);
		this.bt_show_other_ctn.setVisible(isPlayerTurn);


		// Animations: should be played through a queue, otherwise reload is played while shooting is not done yet they play in parallel
		// Animations: Welcome screen
		// - Reload
		// - Shot self (a, b)
		// - Shot opponent (a, b)
		// - Item used
		// - Game over

		// Update the presentation state:
		// - gun state 
		// - chains


		// Actions:
		// - use item, index
		// - shoot self
		// - shoot other

		this.opponent_name.text = otherPlayer.id;


		this.renderHearts(this.my_hearts, localPlayer.health);
		this.renderHearts(this.other_hearts, otherPlayer.health);

		this.renderItems(this.my_items, localPlayer.itemsList, true);
		this.renderItems(this.other_items, otherPlayer.itemsList, false);




		if (gameState.gameinfo.reloadperformed) {
			this.animations.showPopup(this.reload_container, this.reload_bg, this.reload_elements);
			this.animations.setupReloadAnimations(gameState.gameinfo.livebullets, this.bullet, this.cylinder, () => {
				this.animations.hidePopup(this.reload_bg, this.reload_container);
			});
		}


		const hasLastShooter = gameState.playeridlastshooter && gameState.playeridlastshooter.trim() !== '';
		const isLastShotLive = gameState.gameinfo.lastshotlive === true;
		if (hasLastShooter) {
			this.animations.showPopup(this.shooting_container, this.shootPopupBg, this.shooting_elements);
			if (isLastShotLive) {
				this.animations.setupShootingAnimations(this.shooting_gun, this.gun_s, this.shot_effect, this.muzzle, () => {
					this.animations.hidePopup(this.shootPopupBg, this.shooting_container);
				});
			} else {
				this.animations.setupEmptyShootingAnimations(this.shooting_gun, this.gun_s, this.shot_effect, this.muzzle, () => {
					this.animations.hidePopup(this.shootPopupBg, this.shooting_container);
				});
			}
		}


		// Check if conditions have just become true
		// if (hasLastShooter && isLastShotLive) {
		// this.playExplosionVFX();
		// }
	}

	renderItems(itemImages, playerItems, myItems) {
		for (let playerItem of playerItems) {
			var imageName = this.itemImagesMap.get(playerItem.item);
			itemImages[playerItem.index].setTexture(imageName);
		}
	}

	handleItemClick(itemIndex, myItems) {
		let gameState = store.getState().game;
		let playerItems = myItems ? gameState.player1.itemsList : gameState.player2.itemsList;

		let item;
		for (let it of playerItems) {
			if (it.index === itemIndex) {
				item = it;
				break;
			}
		}
		if (item.item === 0) {
			return;
		}

		let imageName = this.itemImagesMap.get(item.item);
		let imageDesc = this.itemDescriptionsMap.get(item.item);
		this.item_popup_image.setTexture(imageName);
		this.item_use_ctn.setVisible(myItems);
		this.item_popup_desc.text = imageDesc;
		this.animations.showPopup(this.popup_container, this.popup_bg, this.popup_anchor_centre);
	}

	renderHearts(heartImages, healthAmount) {
		let index = 0;
		for (let heart of heartImages) {
			if (index < healthAmount) {
				heart.setVisible(true);
			} else {
				heart.setVisible(false);
			}
			index++;
		}
	}


	destroy() {
		// Clean up subscription when the scene is destroyed
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	}
	/* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
export default GameplayScene;