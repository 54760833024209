// src/components/overlays/GameScreen.js
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateGameState } from '../../store/slices/gameSlice'; // Adjust path as needed
import { setLocalPlayer } from '../../store/slices/localPlayerSlice';
import GameService from '../../grpc/services/GameService';
import { initializePhaser } from '../../game/phaserGame'; // Adjust the path as needed
import PropTypes from 'prop-types';

const GRPC_SERVICE_ADDRESS = process.env.REACT_APP_GRPC_SERVICE_ADDRESS || 'https://triumviart-games.com';

function GameScreen({ playerId, gameId, opponentId, onGameOver }) {
  const dispatch = useDispatch();
  const gameState = useSelector((state) => state.game); // Assuming 'game' is the key in your store
  const localPlayerState = useSelector((state) => state.localPlayer);
  const [errorMessage, setErrorMessage] = useState(null);
  const gameService = useMemo(() => new GameService(GRPC_SERVICE_ADDRESS), []);
  const phaserContainerRef = useRef(null); // Ref for Phaser container
  const phaserGameRef = useRef(null); // Ref to store Phaser game instance

  useEffect(() => {
    let gameStateStream;
    let errorStream;

    const value = playerId;
    const newState = {
      id: value
    };
    dispatch(setLocalPlayer(newState));

    const setupGame = async () => {
      try {
        // 1. Connect the player first
        await gameService.sendPlayerAction(playerId, 0);
        console.log(`Player ${playerId} connected.`);

        // 2. After successful connect, subscribe to game state
        console.log('Establishing the stream!');
        gameStateStream = gameService.subscribeToGameState(
          playerId,
          (state) => {
            // Dispatch the new state to the Redux store
            dispatch(updateGameState(state));

            // Check if game ended
            if (state.gameinfo && state.gameinfo.gamestatus !== 0) {
              const winnerId = state.gameinfo.winnerid;
              if (onGameOver) onGameOver(winnerId);
            }
          },
          () => console.log('Game state stream ended.'),
          (err) => console.error('Game state stream error:', err)
        );

        // 3. Subscribe to errors
        errorStream = gameService.subscribeToErrors(
          playerId,
          (errMsg) => {
            console.error('Server error:', errMsg.message);
            setErrorMessage(errMsg.message);
          },
          () => console.log('Error stream ended.'),
          (err) => console.error('Error stream error:', err)
        );

      } catch (err) {
        console.error('Error connecting player:', err);
        setErrorMessage('Failed to connect to the game.');
      }
    };

    setupGame();

    if (phaserContainerRef.current && !phaserGameRef.current) {
      phaserGameRef.current = initializePhaser('phaser-container');
    }

    // Cleanup when component unmounts
    return () => {
      if (phaserGameRef.current) {
        phaserGameRef.current.destroy(true);
        phaserGameRef.current = null;
      }
      if (gameStateStream) gameStateStream.cancel();
      if (errorStream) errorStream.cancel();
    };
    // Only run once for setup
  }, [playerId, onGameOver, gameService, dispatch]);

  // If we haven't received a state update yet, display a loading state
  if (!gameState || !gameState.player1 || !gameState.player2) {
    return (
      <div>
        <p>Waiting for initial game state...</p>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    );
  }

  // Determine local player and opponent
  let localPlayer = gameState.player1;
  let otherPlayer = gameState.player2;
  if (localPlayer.id !== playerId) {
    const temp = localPlayer;
    localPlayer = otherPlayer;
    otherPlayer = temp;
  }

  const isPlayerTurn = gameState.playeridturn === localPlayer.id;

  const shootSelf = async () => {
    try {
      await gameService.sendPlayerAction(playerId, 1);
    } catch (err) {
      console.error('Error shooting self:', err);
      setErrorMessage('Failed to shoot self.');
    }
  };

  const shootOpponent = async () => {
    try {
      await gameService.sendPlayerAction(playerId, 2);
    } catch (err) {
      console.error('Error shooting opponent:', err);
      setErrorMessage('Failed to shoot opponent.');
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Phaser Container */}
      <div
        id="phaser-container"
        ref={phaserContainerRef}
        style={{
          position: 'absolute', // Make it fill the parent container
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1, // Ensure it's below the React content
        }}
      ></div>

      {/* Existing Game UI */}
      <div
        style={{
          position: 'absolute', // Overlay on top of Phaser container
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2, // Ensure it is above the Phaser container
          pointerEvents: 'none', // Allow Phaser interactions to pass through
        }}
      >
        <div style={{ pointerEvents: 'auto', padding: '20px' }}>
          {/* <h2>Game: {gameId}</h2> */}
          {/* <p>Opponent: {otherPlayer.id}</p> */}
          {/* <p>Your Health: {localPlayer.health}</p> */}
          {/* <p>Opponent Health: {otherPlayer.health}</p> */}
          {/* <p>Current Turn: {gameState.playeridturn}</p> */}

          {/* {gameState.gameinfo.reloadperformed && (
            <p>
              Gun reloaded: {gameState.gameinfo.livebullets}/{gameState.gameinfo.totalbullets}
            </p>
          )} */}

          {isPlayerTurn ? (
            <div>
              <button onClick={shootSelf}>Shoot Self</button>
              <button onClick={shootOpponent}>Shoot Opponent</button>
            </div>
          ) : (
            <p>Opponent is choosing...</p>
          )}
          {gameState.gameinfo.lastshotlive && <p>BANG</p>}
          {gameState.playeridlastshooter && !gameState.gameinfo.lastshotlive && <p>CLICK</p>}

          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
}

GameScreen.propTypes = {
  playerId: PropTypes.string.isRequired,
  gameId: PropTypes.isRequired,
  opponentId: PropTypes.isRequired,
  onGameOver: PropTypes.func.isRequired,
};

export default GameScreen;