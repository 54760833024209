export class Animations {
    scene;

    constructor(gameScene) {
        this.scene = gameScene;
    }

	setupEmptyShootingAnimations(gunGroup, gunImage, shotEffect, muzzle, onFinish) {
        gunGroup.setAngle(0);
        shotEffect.setVisible(false);
        muzzle.setVisible(false);

        let originalX = gunGroup.x;
        let originalY = gunGroup.y;

        const recoilChain = this.scene.tweens.chain({
        targets: gunGroup,
        persist: true,
        tweens: [
            {
                // A) Angle goes from 0 to 35 over e.g. 1 second
                angle: {from: 0, to: 35 },
                duration: 1000,
                ease: 'Linear',
                onStart: () => {
                    // Reset at the start of each cycle
                    // this.shooting_gun.setAngle(0);
                    shotEffect.setVisible(false);
                    gunGroup.setPosition(originalX, originalY);
                },
                onComplete: () => {
                    gunImage.setFrame(1);
                    this.scene.time.delayedCall(50, () => {
                        gunImage.setFrame(0);
                    });
                }
            },
            {
                angle: 35,
                duration: 500,
                ease: 'Expo.easeOut',
                onComplete: () => {
                    gunImage.setFrame(2);
                }
            },
            {
                // delay: 100,
                // E) Finally, return gun to original angle (0) & position (400)
                //    Hide muzzle flash as it cools down.
                angle: 0,
                x: originalX,
                duration: 500,
                ease: 'linear'
            }
            ]
        });

        // 3) Restart the chain repeatedly
        recoilChain.play(); // start it once

        // When the final tween finishes, the chain is considered "complete"
        recoilChain.on('complete', () => {
            this.scene.time.delayedCall(400, () => {
				onFinish();
            });
        });
    }

    setupShootingAnimations(gunGroup, gunImage, shotEffect, muzzle, onFinish) {
        gunGroup.setAngle(0);
        shotEffect.setVisible(false);
        muzzle.setVisible(false);

        let originalX = gunGroup.x;
        let originalY = gunGroup.y;

        let xOffset = -100;

        // this.shooting_gun.setOrigin(0.5, 1.0);

        const recoilChain = this.scene.tweens.chain({
        targets: gunGroup,
        persist: true,
        tweens: [
            {
                // A) Angle goes from 0 to 35 over e.g. 1 second
                angle: {from: 0, to: 35 },
                duration: 1000,
                ease: 'Linear',
                onStart: () => {
                    // Reset at the start of each cycle
                    // this.shooting_gun.setAngle(0);
                    shotEffect.setVisible(false);
                    gunGroup.setPosition(originalX, originalY);
                },
                onComplete: () => {
                    gunImage.setFrame(1);
                    this.scene.time.delayedCall(50, () => {
                        gunImage.setFrame(0);
                    });
                }
            },
            {
                delay: 500,
                // A) Gun quickly rotates up from 0 to -20 degrees in 100ms
                //    (negative angle means it flips upwards if your sprite is facing right).
                //    Also moves backward in X a little to simulate recoil.
                angle: 35+30,
                x: gunGroup.x - xOffset,
                duration: 80,
                ease: 'Expo.easeOut',
                onStart: () => {
                    // Make muzzle flash visible at the MOMENT of over-rotate
                    shotEffect.setVisible(true);
                    gunImage.setFrame(2);
                    this.showMuzzle(muzzle);
                }
            },
            {
                // B) Slight "over-rotate" (back a bit more), very fast, for snappy recoil
                angle: 35+35,
                // x: this.shooting_gun.x - xOffset*.5,
                duration: 60,
                ease: 'Quad.easeOut'
            },
            {
                delay: 50,
                // C) Move gun back to near -10 degrees and reduce the backward offset
                //    to show it settling from the recoil.
                angle: 35+10,
                // x: this.shooting_gun.x + xOffset * .1,
                duration: 100,
                ease: 'linear',
            },
            {
                // delay: 100,
                // E) Finally, return gun to original angle (0) & position (400)
                //    Hide muzzle flash as it cools down.
                angle: 0,
                x: originalX,
                duration: 500,
                ease: 'linear',
                onComplete: () => {
                    // Turn off muzzle flash

                }
            },
            {
            // // F) Optional delay before the sequence starts again
            // 	delay: 800,
            // 	angle: 35
            }
            ]
        });

        // 3) Restart the chain repeatedly
        recoilChain.play(); // start it once

        // When the final tween finishes, the chain is considered "complete"
        recoilChain.on('complete', () => {
            this.scene.time.delayedCall(400, () => {
				onFinish();
            // // Start over to repeat indefinitely
            //     shotEffect.setVisible(false);
            //     recoilChain.restart();
            });
        });
    }
	showMuzzle(muzzleImage) {
        // Immediately make muzzle visible and set first frame
        muzzleImage.setVisible(true);
        muzzleImage.setFrame(0);

        // After 100 ms, switch to the next frame
        this.scene.time.delayedCall(100, () => {
            muzzleImage.setFrame(1);
        });

        // After another 100 ms (200 ms total), switch again
        this.scene.time.delayedCall(300, () => {
            muzzleImage.setFrame(2);
        });

        // After 1000 ms total, hide it
        this.scene.time.delayedCall(150, () => {
            muzzleImage.setVisible(false);
        });
    }

	setupReloadAnimations(liveCartiges, bulletImage, cylinderImage, onFinish) {	
        bulletImage.setFrame(1);
		let currentIndex = 0;
		// Variables to track which frame the cylinder should show next
		let cylinderFrameIndex = 6;  // starts at 6
		let frameDirection = -1;     // we move from 6 down to 0 first
		cylinderImage.setFrame(6);
		// 1) Rotate cylinder continuously
		//    Tween approach: rotate from angle=0 to 360 in 2s, repeat forever
		let rotateTween = this.scene.tweens.add({
			targets: cylinderImage,
			angle: 360,       // Will rotate from 0 to 360
			duration: 1500,   // 1.5 seconds for full rotation
			repeat: -1,       // Infinite repeats
			ease: 'Linear'
		});

		var originalX = bulletImage.x;
		var originalY = bulletImage.y;
		var originalScale = bulletImage.scaleY;
		var stretchY = originalScale * 0.3;

		const chain = this.scene.tweens.chain({
			targets: bulletImage,
			persist: true,
			tweens: [
				{
					y: originalY,
					x: originalX,
					scaleY: originalScale,
					ease: 'linear',
					duration: 100
				},
				{
					alpha: { from: 0, to: 1},
					ease: 'expo.in',
					duration: 150
				},
				{
					delay: 300,
					y: {from: originalY, to: cylinderImage.y},
					scaleY: stretchY,
					alpha: {from: 1, to: 0},
					ease: 'power3',
					duration: 200
				},

			]
		});

		// chain.();
		chain.on('complete', () => {
			currentIndex++;
			if(currentIndex >= liveCartiges) {
				bulletImage.setFrame(0);
			}
			// Move frame index in current direction
			cylinderFrameIndex += frameDirection;

			if (cylinderFrameIndex < 0) {
				cylinderFrameIndex = 6;
				frameDirection = -1;
			}
			// Apply new frame to the cylinder
			cylinderImage.setFrame(cylinderFrameIndex);
			if(cylinderFrameIndex != 0) {
				chain.restart();
			} else {
				rotateTween.stop();
				this.scene.tweens.add({
					targets: cylinderImage,
					angle: (cylinderImage.angle + 50) % 360,       // Will rotate from 0 to 360
					duration: 500,   // 1.5 seconds for full rotation
					ease: 'power3'
				});
				this.scene.time.delayedCall(800, () => {
					bulletImage.setAlpha(0);
					bulletImage.setPosition(originalX, originalY);
					bulletImage.setScale(bulletImage.scaleX, originalScale);
					bulletImage.setFrame(1);
					onFinish();
				});
			}
		});
	}

	showPopup(popup_container, popup_bg_image, popup_content) {
		popup_container.visible = true;
		popup_container.x = this.scene.scale.width / 2;
		popup_container.y = this.scene.scale.height / 2;

		const pw = popup_bg_image.displayWidth;
		const ph = popup_bg_image.displayHeight;
		const sw = this.scene.scale.width;
		const sh = this.scene.scale.height;

		const finalScaleX = sw / pw;
		const finalScaleY = sh / ph;

		// Start popup_container at half scale
		popup_container.setScale(finalScaleX * 2, finalScaleY * 2);
		popup_container.setAlpha(0);

		// Set popup_anchor_centre to the inverse scale so net = 1 at the start
		// popup_content == popup_anchor_centre
		popup_content.setScale(
			1 / (finalScaleX * 0.5),
			1 / (finalScaleY * 0.5)
		);

		// Tween parent container
		this.scene.tweens.add({
			targets: popup_container,
			scaleX: finalScaleX,
			scaleY: finalScaleY,
			alpha: 1,
			duration: 500,
			ease: 'Expo.Out'
		});

		// Tween the anchor centre container inversely
		this.scene.tweens.add({
			targets: popup_content,
			scaleX: 1 / finalScaleX,
			scaleY: 1 / finalScaleY,
			duration: 500,
			ease: 'Expo.Out'
		});
	}

	hidePopup(popup_bg_image, popup_container) {
		// Recalculate scales (in case screen size changed)
		const pw = popup_bg_image.displayWidth;
		const ph = popup_bg_image.displayHeight;
		const sw = this.scene.scale.width;
		const sh = this.scene.scale.height;

		const finalScaleX = sw / pw;
		const finalScaleY = sh / ph;

		// Animate it back to half scale and alpha=0
		this.scene.tweens.add({
			targets: popup_container,
			scaleX: finalScaleX * 2,
			scaleY: finalScaleY * 2,
			alpha: 0,
			duration: 500,
			ease: 'Expo.Out',
			onComplete: () => {
				popup_container.visible = false;
			}
		});
	}
}