import { GameServiceClient } from '../generated/game_grpc_web_pb';
import { PlayerId, PlayerAction, Empty } from '../generated/game_pb'; 
// Adjust paths if needed.
// Also ensure ActionType enum is accessible (often from the generated JS file).

class GameService {
  constructor(host) {
    this.client = new GameServiceClient(host, null, null);
  }

  /**
   * Subscribes to the game state stream for a given playerId.
   * @param {string} playerId
   * @param {function} onData - Called with each GameState object as JS object.
   * @param {function} onEnd - Called when the stream ends.
   * @param {function} onError - Called if an error occurs in the stream.
   * @returns {ClientReadableStream<GameState>}
   */
  subscribeToGameState(playerId, onData, onEnd, onError) {
    const request = new PlayerId();
    request.setId(playerId);
    console.log('Subscribing to game state stream...');
    const stream = this.client.reportGameState(request, {});

    stream.on('data', (response) => {
      onData(response.toObject());
    });
    stream.on('end', () => {
      console.log('Got end!');
      if (onEnd) onEnd();
    });
    stream.on('error', (err) => {
      console.log('Got error!');
      if (onError) onError(err);
    });

    return stream;
  }

  /**
   * Subscribes to error reports for a given playerId.
   * @param {string} playerId
   * @param {function} onData - Called with each GameError object as JS object.
   * @param {function} onEnd - Called when the stream ends.
   * @param {function} onError - Called if an error occurs in the stream.
   * @returns {ClientReadableStream<GameError>}
   */
  subscribeToErrors(playerId, onData, onEnd, onError) {
    const request = new PlayerId();
    request.setId(playerId);

    const stream = this.client.reportErrors(request, {});

    stream.on('data', (response) => {
      onData(response.toObject());
    });
    stream.on('end', () => {
      if (onEnd) onEnd();
    });
    stream.on('error', (err) => {
      if (onError) onError(err);
    });

    return stream;
  }

  /**
   * Sends a player action (e.g., ShootSelf or ShootOther).
   * @param {string} playerId
   * @param {ActionType} actionType - from enum ActionType (e.g., ActionType.SHOOT_SELF)
   * @returns {Promise<void>}
   */
  async sendPlayerAction(playerId, actionType) {
    const request = new PlayerAction();
    request.setPlayerid(playerId);
    request.setAction(actionType);

    return new Promise((resolve, reject) => {
      this.client.sendPlayerAction(request, {}, (err, response) => {
        if (err) {
          return reject(err);
        }
        // response is Empty
        resolve();
      });
    });
  }
}

export default GameService;