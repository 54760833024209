import { configureStore } from '@reduxjs/toolkit';
import gameReducer from './slices/gameSlice';
import localPlayerReducer from './slices/localPlayerSlice'

const store = configureStore({
  reducer: {
    game: gameReducer, // Add more reducers if needed in the future
    localPlayer: localPlayerReducer
  },
});

export default store;